//screen sizes
$small-size: 20rem; //mobile
$medium-size: 48rem; //small tablet
$large-size: 64rem; //medium tablet
$huge-size: 84rem; //desktop

// color
$white: #ffffff;
$black: #000000;
$red: #FF0000;

$dark-cyan: #009688;

$primary-green: #85c226;
$primary-grey: #495678;
$primary-grey-text: #495775;
$primary-grey-background: #f2f2f2;
$primary-orange: #ff6500;

$orange-1: #F3A641;
$orange-2: #F2994A;

$dark-purple: #1f2633;
$light-purple: #cce;
$light-pink: #FF9999;
$light-grey: #E2E2E2;

$grey-1: #bebebe;
$grey-2: #777777;
$grey-3: #333333;
$grey-4: #eaeaea;
$grey-5: #f1f1f1;
$grey-6: #F4F4F4;
$grey-7: #666666;
$grey-8: #949494;
$grey-9: #B7B7B7;
$grey-10: #737373;
$grey-11: #ebebeb;
$grey-12: #9D9D9D;
$grey-13: #C4C4C4;
$grey-14: #848484;
$grey-15: #999999;
$grey-16: #CCCCCC;
$grey-17: #CACACA;
$grey-18: #A5A5A5;
$grey-19: #303030;
$grey-20: #f9f9f9;
$grey-21: #ced4da;
$grey-22: #D9D9D9;

$blue-1: #899cc5;
$blue-2: #819DCD;
$blue-3: #5473AC;
$blue-4: #46A6DB;
$blue-5: #4BADE8;

$green-1: #86c127;

$orange-1: #EAC55B;

$red-1: #F45D5D;
$red-2: #FF9999;
$red-3: #F05454;
$red-4: #F46464;
$red-5: #F55753;
$red-6: #D45229;
$red-7: #EF9595;

$primary-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
$box-shadow-1: 4px 4px 4px rgba(0, 0, 0, 0.2);
$box-shadow-2: 0px 4px 4px rgba(0, 0, 0, 0.2);
$box-shadow-3: 2px 2px 4px rgba(0, 0, 0, 0.15);
$box-shadow-4: 2px 2px 6px rgba(0, 0, 0, 0.15);
$box-shadow-5: 0px 2px 6px rgba(0, 0, 0, 0.15);
$box-shadow-6: 4px 4px 6px rgba(0, 0, 0, 0.2);

$gray-background: #e5e5e5;

// jobseeker sign in colors
$jobseeker-signin-hover: #5b9918;
$jobseeker-signin-checkbox: #a8a8a8;
$jobseeker-signin-faded-text: #c4c4c4;

// jobseeker error box colors
$jobseeker-error-box-bg-color: #f2dede;
$jobseeker-error-box-text-color: #c16868;

// jobseeker recruitment box colors
$jobseeker-rec-box-background: rgba(194, 232, 133, 0.22);
$jobseeker-rec-box-company-registration-border: #689127;

// font size
$font-size-10: 0.625rem;
$font-size-11: 0.6875rem;
$font-size-12: 0.75rem;
$font-size-13: 0.81rem;
$font-size-14: 0.87rem;
$font-size-15: 0.94rem;
$font-size-16: 1rem;
$font-size-18: 1.125rem;
$font-size-19: 1.1875rem;
$font-size-20: 1.25rem;
$font-size-24: 1.5rem;
$font-size-25: 1.57rem;
$font-size-30: 1.87rem;
$font-size-35: 2.1875rem;
$font-size-48: 3rem;

//search-panel-size
$search-panel-min-height: 21.125rem;
$search-panel-medium-height: 20.5rem;
$search-panel-large-height: 13.75rem;
$search-panel-huge-height: 9.25rem;


//banners
$banner-width-auto: 100%;
$banner-height-auto: 100%;

$banner-width-1: 45.5rem;
$banner-height-1: 5.625rem;
$banner-width-resp-1: 20rem;
$banner-height-resp-1: 3.125rem;

$banner-width-2: 18.75rem;
$banner-height-2: 100%;