@use '@angular/material' as mat;
@use '../../../libs/ui/src/lib/theming/index' as ui;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();


$mat-hr-green: (
    50 : #f0f8e5,
    100 : #daedbe,
    200 : #c2e193,
    300 : #aad467,
    400 : #97cb47,
    500 : #85c226,
    600 : #7dbc22,
    700 : #72b41c,
    800 : #68ac17,
    900 : #559f0d,
    A100 : #e4ffce,
    A200 : #c8ff9b,
    A400 : #acff68,
    A700 : #9eff4e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-hr-blue: (
    50 : #e9ebef,
    100 : #c8ccd7,
    200 : #a4abbc,
    300 : #8089a1,
    400 : #646f8c,
    500 : #495678,
    600 : #424f70,
    700 : #394565,
    800 : #313c5b,
    900 : #212b48,
    A100 : #8daaff,
    A200 : #5a83ff,
    A400 : #275dff,
    A700 : #0e4aff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$public-web-app-primary: mat.define-palette($mat-hr-blue);
$public-web-app-accent: mat.define-palette($mat-hr-green);

// The warn palette is optional (defaults to red).
$public-web-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$public-web-app-theme: mat.define-light-theme($public-web-app-primary, $public-web-app-accent, $public-web-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($public-web-app-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

input, select, .mat-select, .p-dropdown, .p-treeselect, .mat-select-trigger{
    height: 2.3750rem !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix{
    padding: 0rem !important;
}

.mat-select-arrow-wrapper{
    height: unset;
}

input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
}

.mat-checkbox-frame {
    border-width: 1px !important;
    border-color: #85C226;
}

.mat-radio-outer-circle {
    border-width: 1px !important;
    border-color: #85C226;
}

.mat-radio-checked .mat-radio-inner-circle {
    transform: scale(0.75) !important;
}

// .mat-select-value{
//     padding: 0.5rem;
// }

.mat-error {
    padding: 1px;
}

.mat-checkbox-layout {
    white-space: normal !important;
}

.mat-radio-label {
    white-space: normal !important;
}

.mat-simple-snackbar{
    justify-content: center !important;
}

.p-multiselect:not(.p-disabled):hover {
    border-color: #ced4da !important;
}

.p-inputtext{
    border: 0.0635rem solid #E9ECF0 !important;
}


@import 'core-styles/components/primeng-mixins.scss';
@import 'core-styles/components/primeng-styles.scss';

.p-focus{
    @include primeng-focused();
}

.p-treeselect-header{
    @include primeng-content();
}

.p-inputtext{
    border: 0.0635rem solid #E9ECF0 !important;
    @include primeng-content();
}

.p-inputtext:focus{
    @include primeng-focused();
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
    @include primeng-focused();
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    @include primeng-focused();
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    @include primeng-multiselect();
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    @include primeng-focused();
}

.p-tree .p-tree-container .p-treenode .p-treenode-content{
    @include primeng-content();
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    border-color: transparent;
    @include primeng-multiselect();
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
    @include primeng-focused();
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    @include primeng-multiselect();
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    @include primeng-focused();
}

.p-checkbox-icon{
    @include primeng-checkbox();
}

.p-checkbox .p-checkbox-box.p-highlight {
    @include primeng-checkbox();
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{
    @include primeng-checkbox-hover();
}

.p-indeterminate{
    @include primeng-checkbox();
}

@import "../src/styles/components/_dialogs.scss";

@import "core-styles/base/_resets.scss";

@import "../src/styles/components/_snackbar.scss";

@import "../src/styles/components/_carousel.scss";

@import "../src/styles/components/_material-styles.scss";



$header-min-height: 3rem;
$header-medium-height: 4rem;

$min-spinner-top: calc(#{$header-min-height} + 21.125rem);
$medium-spinner-top: calc(#{$header-medium-height} + 13.75rem);
$large-spinner-top: calc(#{$header-medium-height} + 9.25rem);

@include ui.spinner-top-with-search-panel($min-spinner-top, $medium-spinner-top, $large-spinner-top);