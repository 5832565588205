$small-size: 20rem; //mobile
$medium-size: 48rem; //small tablet
$large-size: 64rem; //medium tablet
$huge-size: 84rem; //desktop

@mixin small($min-width) {
    @media (min-width: #{($min-width)}){
        @content;
    }
}
@mixin medium($medium-width) {
    @media (min-width: #{$medium-width}){
        @content;
    }
}
@mixin large($large-width) {
    @media (min-width: #{$large-width}){
        @content;
    }
}
@mixin huge($huge-width) {
    @media (min-width: #{$huge-width}){
        @content;
    }
}

@mixin responsive($min-width){
    @media (min-width: #{$min-width}){
        @content;
    }
}





@mixin font-style($color, $font-size) {
    color: $color;
    font-size: $font-size;
}

@mixin flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
