.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover{
    background: none !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: none !important;
}

.p-overlay{
    width: min-content !important;
    z-index: 99999 !important;
}

.p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none !important;
    border-color: none !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
    background: none !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #fff !important;
}