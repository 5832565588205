@import "core-styles/abstracts/_mixins.scss";

//front page - search panel multiple select
.cdk-overlay-container .cdk-overlay-pane .search-panel-multiple-select{
    min-width: 100% !important;
    margin-left: 0.4rem;
    padding-right: 2.1rem;
    margin-top: -0.875rem;

    @include responsive($medium-size){
        margin-left: 0.91rem;
    }

    @include responsive($large-size){
        padding-right: 0.91rem !important;
        margin-left: 1.5rem;
        padding-left: 1.25rem;
    }

    @include responsive($huge-size){
        padding-right: 0.75rem !important;
    }
}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .search-panel-multiple-select{
    background: $primary-green;
  }


//jobseeker profile - personal info
.cdk-overlay-container .cdk-overlay-pane .personal-info-select-driving-licence{
    padding-right: 2rem !important;
    min-width: 100% !important; 
}

.cdk-overlay-container .cdk-overlay-pane .personal-info-select-vehicle-type{
    min-width: 99% !important;
    right: 0rem !important;
    left: 0rem !important;
}