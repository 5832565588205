@mixin primeng-focused($outline: none, $outline-offsert: none, $box-shadow: none){
    outline: $outline !important;
    outline-offset: $outline-offsert !important;
    box-shadow: $box-shadow !important;
}

@mixin primeng-checkbox($background-color: #85c226, $border-color: #85c226){
    background: $background-color !important;
    border-color: $border-color !important;
}

@mixin primeng-checkbox-hover($border-color: #85c226){
    border-color: $border-color !important;
}

@mixin primeng-content($font-size: 14px, $background: #ffffff) {
    font-size: $font-size !important;
    background: $background !important;
}

@mixin primeng-multiselect($color: #495057, $background: none){
    color: $color !important;
    background: $background !important;
}