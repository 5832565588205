@mixin button-uncoloured($vertical-padding, $horizontal-padding, $color, $radius: 1.1rem){
    width: auto;
    padding: $vertical-padding $horizontal-padding;
    border: 1px solid $color;
    border-radius: $radius;
    color: $color;
    background-color: white;
    outline: none;
    cursor: pointer;
}

@mixin button-coloured($vertical-padding, $horizontal-padding, $background-color, $radius: 1.1rem, $color: $white){
    width: auto;
    padding: $vertical-padding $horizontal-padding;
    border-radius: $radius;
    color: $color;
    background-color: $background-color;
    outline: none;
    cursor: pointer;
}

@mixin button-coloured-fixed-size($width, $height, $color, $radius: 1.1rem, $specify-min-width: false){
    
    @if $specify-min-width == true{
        min-width: $width;
    }
    @else{
        width: $width;
    }

    height: $height;
    border: 1px solid $color;
    border-radius: $radius;
    color: $color;
    background-color: $color;
    outline: none;
    cursor: pointer;
}

@mixin button-uncoloured-fixed-size($width, $height, $color, $radius: 1.1rem){
    min-width: $width;
    height: $height;
    border: 1px solid $color;
    border-radius: $radius;
    color: $color;
    background-color: white;
    outline: none;
    cursor: pointer;
}

@mixin button-uncoloured-fixed-size-2($width, $height, $border-color, $text-color, $radius: 1.1rem){
    min-width: $width;
    height: $height;
    border: 1px solid $border-color;
    border-radius: $radius;
    color: $text-color;
    background-color: white;
    outline: none;
    cursor: pointer;
}

@mixin button-style-none{
    background: none;
    border: none;
    padding: 0;
    outline: none;
    cursor: pointer;
}