@import "../../../../core-styles/src/core-styles/abstracts/mixins";


@mixin spinner-top-with-search-panel($min-height, $medium-height, $large-height){
    .spinner--fixed-with-search-panel{
        top: $min-height !important;

        @include medium($medium-size){
            top: $medium-height !important;
        }

        @include large($large-size){
            top: $large-height !important;
        }
    }
}
