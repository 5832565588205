@import "core-styles/abstracts/_mixins.scss";
@import "../abstracts/_variables.scss";

.snackbar-success{
    background: $primary-green;
    color: $white;
    text-align: center;
    position: absolute;
    margin: 0 !important;
    right: 0.25rem;
    top: $header-min-height + 0.5rem;

    @include medium($medium-size){
        top: $header-medium-height + 0.5rem;
    }
}

.snackbar-fail{
    background: $red-3;
    color: $white;
    text-align: center;
    position: absolute;
    margin: 0 !important;
    right: 0.25rem;
    top: $header-min-height + 0.5rem;

    @include medium($medium-size){
        top: $header-medium-height + 0.5rem;
    }
}   