@import "core-styles/components/_buttons.scss";
@import "core-styles/abstracts/_mixins.scss";
@import "../abstracts/_variables.scss";
@import "../layout/seraphinite/_header.scss";

.confirm-dialog-container{
    width: 47.4375rem;
    font-size: 1.125rem;
    line-height: 21px;

    mat-dialog-actions{
        display: flex;
        justify-content: flex-end;
    }

    .button{
        font-size: 0.9375rem;
        @include  button-style-none();
    }

    .button--yes{
        margin-right: 2.3125rem;
    }
}

.exeption-dialog-container{
    mat-dialog-content{
        color: $grey-3;
        font-size: $font-size-15;
        padding-bottom: 1rem;
    }

    mat-dialog-actions{
        display: flex;
        justify-content: flex-end;
    }

    .button{
        @include button-style-none();
        color: $primary-grey;
        font-size: $font-size-18;
        cursor: pointer;

        a{
            text-decoration: none;
            color: $primary-grey;
        }

        a:active {
            color: unset;
        }
    }

    .button--yes{
        margin-right: 3.125rem;
    }
}

.confirm-edit-dialog-container{
    max-width: unset !important;
    width: 100%;
    height: 100%;

    @include large($large-size){
        width: unset;
        height: unset;
    }

    mat-dialog-container{
        padding: calc(2.5rem + #{$header-min-height}) 1.5rem;

        @include large($large-size){
            padding-top: 2.5rem;
            padding-bottom: 2.5rem;
        }
    }
}

.jobseeker-document-upload-dialog-container{
    max-width: unset !important;
    width: 100%;
    height: 100%;

    @include large($large-size){
        width: unset;
        height: unset;
    }

    mat-dialog-container{
        padding: calc(2.5rem + #{$header-min-height}) 1.5rem;

        @include large($large-size){
            width: 42.375rem;
            height: 28.5rem;
            padding: 1.875rem 6.25rem;
        }
    }
}

.jobseeker-edit-language-dialog-container{
    max-width: unset !important;
    width: 100%;
    height: 100%;

    @include large($large-size){
        width: unset;
        height: unset;
    }

    mat-dialog-container{
        padding: calc(2.5rem + #{$header-min-height}) 1.5rem;

        @include large($large-size){
            width: 51.8125rem;
            height: 22.375rem;
            padding: 1.875rem 6.25rem;
        }
    }
}


.info-dialog-container{
    max-width: 100% !important;

    @include large($large-size){
      max-width: 45rem !important;
    }

    mat-dialog-container{
        padding-top: calc(1.5rem + #{$header-min-height});

        @include large($large-size){
            padding-top: 1.5rem;
            max-height: 48.125rem;
        }
    }
}

.common-dialog-container {
    //margin-top: 3rem;
    max-width: calc(100vw - 2rem) !important;
    max-height: calc(100vh - 7rem);
    @include responsive($medium-size){
        // margin-top: 7rem;
        max-width: calc(100vw - 2rem) !important;
        max-height: min(calc(100vh - 11rem), 48rem);
    }

    @include responsive($large-size){
         max-width: 80vw;
    }
}